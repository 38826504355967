<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{
        id
          ? "Editar Reaproveitamento de Trecho"
          : "Cadastrar Reaproveitamento de Trecho"
      }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Reaproveitamento de Trecho</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12">
              <span class="primary--text">Linha *</span>
              <v-autocomplete
                v-model="dados.id_linha"
                :rules="fieldRules"
                required
                :items="linhas"
                item-text="descricao"
                item-value="id"
                placeholder="Informe a linha"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Origem (Venda) *</span>
              <v-autocomplete
                v-model="dados.id_origem_venda"
                :rules="fieldRules"
                required
                :items="localidades"
                item-text="nome"
                item-value="id"
                placeholder="Informe a origem (venda)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Destino (Venda) *</span>
              <v-autocomplete
                v-model="dados.id_destino_venda"
                :rules="fieldRules"
                required
                :items="localidades"
                item-text="nome"
                item-value="id"
                placeholder="Informe o destino (venda)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Origem (Impressão) *</span>
              <v-autocomplete
                v-model="dados.id_origem_imp"
                :rules="fieldRules"
                required
                :items="localidades"
                item-text="nome"
                item-value="id"
                placeholder="Informe a origem (impressao)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Destino (Impressão) *</span>
              <v-autocomplete
                v-model="dados.id_destino_imp"
                :rules="fieldRules"
                required
                :items="localidades"
                item-text="nome"
                item-value="id"
                placeholder="Informe o destino (impressao)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      localidades: [],
      linhas: [],
      dados: {
        id: "",
        id_linha: "",
        id_origem_venda: "",
        id_destino_venda: "",
        id_origem_imp: "",
        id_destino_imp: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.$http
      .get(`/localidades`)
      .then((r) => {
        this.localidades = r.data;
        this.$store.dispatch("activeLoader", false);
      })
      .catch((e) => {
        this.$store.dispatch("activeLoader", false);
        this.message.text = e;
        this.message.snacshow = true;
        this.message.type = "success";
      });
    this.$http
      .get(`/linhas`)
      .then((r) => {
        this.linhas = r.data;
        this.$store.dispatch("activeLoader", false);
      })
      .catch((e) => {
        this.$store.dispatch("activeLoader", false);
        this.message.text = e;
        this.message.snacshow = true;
        this.message.type = "success";
      });
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/reaproveitamento/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.nome = this.dados.nome
        ? this.dados.nome.toUpperCase()
        : this.dados.nome;
      this.dados.cidade = this.dados.cidade
        ? this.dados.cidade.toUpperCase()
        : this.dados.cidade;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/reaproveitamento/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/rtrechos/gerenciar"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/reaproveitamento/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/rtrechos/gerenciar");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
